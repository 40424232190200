@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.banner {
  background-color: #ff0000;
  padding: 10px 6px;
  position: relative;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001;
  .text {
    font-family: variable.$primaryFont;
    font-size: variable.$size16;
    line-height: 16px;
    flex: 1;
    color: variable.$whiteColor;
    text-align: center;
    a {
      color: variable.$whiteColor;
      text-decoration: underline;
      font-family: variable.$primaryFont;
    }
    &::first-letter {
      text-transform: uppercase;
    }
    strong {
      color: variable.$whiteColor;
      display: inline;
      padding: 0;
      font-family: variable.$primaryFontBold;
    }
    em {
      text-transform: uppercase;
      text-decoration: underline;
      font-family: variable.$primaryFontBold;
    }
  }
}
