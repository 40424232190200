@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.primary {
  @include mixin.buttonPrimary;
  position: relative;
  img {
    position: absolute !important;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -12px !important;
  }
  &:hover {
    background-color: variable.$buttonColorHover;
  }
}
.puce {
  &::after {
    display: inline-block;
    content: "";
    color: variable.$whiteColor;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid variable.$whiteColor;
    border-left: 2px solid variable.$whiteColor;
    transform: rotate(135deg);
    vertical-align: middle;
    margin-top: -5px;
  }
  &:hover {
    &::after {
      border-color: variable.$orangeLightColor;
    }
  }
}
.secondary {
  @include mixin.buttonSecondary;
  position: relative;
}
.default {
  @include mixin.buttonDefault;
  position: relative;
}

.secondaryGreen {
  @include mixin.buttonSecondaryGreen;
  position: relative;
}
.editBtn {
  @include mixin.buttonSecondaryGreen;
  border-color: variable.$primarylightColor;
  background-color: rgb(255 255 255 / 50%);
  color: variable.$blackColor;
  text-transform: uppercase;
  &:hover {
    background-color: variable.$primarylightColor;
    color: variable.$blackColor;
  }
  img {
    width: 16px;
    height: 17px;
    margin-top: -8px;
  }
}
.rounded {
  border-radius: 45px;
}
.maxContent {
  max-width: max-content;
  inline-size: max-content;
  padding-inline: 16px;
}
