@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  a {
    font-size: variable.$size14;
    padding: 6px 10px;
    img {
      width: 12px;
    }
  }
  &.puce {
    a {
      padding-right: 25px;
    }
  }
}

@include mixin.mediaDesktopSize() {
  .root {
    a {
      font-size: variable.$size18;
      padding: 10px 18px;
      img {
        width: 16px;
      }
    }
    &.puce {
      a {
        padding-right: 30px;
      }
    }
  }
}
