@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  @include mixin.buttonPrimary;
  img {
    position: absolute;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -12px;
  }
}
.secondary {
  @include mixin.buttonSecondary;
  img {
    position: absolute;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -12px;
  }
}
.default {
  @include mixin.buttonDefault;
  position: relative;
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$orangeLightColor;
    color: variable.$orangeLightColor;
    &:hover {
      background-color: variable.$orangeLightColor !important;
      color: variable.$whiteColor !important;
    }
  }
}

.secondaryGreen {
  @include mixin.buttonSecondaryGreen;
}
.rounded {
  border-radius: 45px;
}
.maxContent {
  max-width: max-content;
  inline-size: max-content;
  padding-inline: 20px;
}
.puce {
  &::after {
    display: inline-block;
    content: "";
    color: variable.$whiteColor;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid variable.$whiteColor;
    border-left: 2px solid variable.$whiteColor;
    transform: rotate(135deg);
    vertical-align: middle;
    margin-top: -5px;
  }
  &:hover {
    &::after {
      border-color: variable.$orangeLightColor;
    }
  }
}
