@font-face {
  font-family: "GoodHeadlinePro";
  src: url("/static/fonts/GoodHeadlineProCondMedium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Regular";
  src: url("/static/fonts/Chivo-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Bold";
  src: url("/static/fonts/Chivo-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/static/fonts/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/static/fonts/Inter-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/static/fonts/Inter18pt-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/static/fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Vinyl OT";
  src: url("/static/fonts/VinylOT-Regular.woff2") format("woff2"),
    url("/static/fonts/VinylOT-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RocaOne-Rg";
  src: url("/static/fonts/RocaOne-Rg.woff2") format("woff2"),
    url("/static/fonts/RocaOne-Rg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Light";
  src: url("/static/fonts/Chivo-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("/static/fonts/OpenSans.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
