@use "@styles/variables" as variable;

.root {
  width: 100%;
  max-width: 419px;
  margin: 0 auto 20px;
  fieldset {
    border: none;
    display: block;
    legend {
      color: variable.$primaryColor;
      font-family: variable.$titlesFont;
      margin-bottom: 25px;
      display: block;
    }
    > div {
      padding: 0.5rem 0;
      display: grid;
      gap: 1rem;
      position: relative;
      span {
        color: variable.$primaryColor;
        font-size: 13px;
        font-family: variable.$primaryFont;
        cursor: pointer;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        width: 3px;
        height: 10px;
        background-color: variable.$primaryColor;
        top: 10px;
        z-index: 2;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: -1px;
        width: 3px;
        height: 10px;
        background-color: variable.$primaryColor;
        top: 10px;
        z-index: 2;
      }
      .startLabel {
        position: absolute;
        top: -15px;
        color: variable.$primaryColor;
        font-size: 13px;
        line-height: 20px;
        font-family: variable.$primaryFontBold;
      }
      .endLabel {
        position: absolute;
        top: -15px;
        right: 0;
        color: variable.$primaryColor;
        font-size: 13px;
        line-height: 20px;
        font-family: variable.$primaryFontBold;
      }
    }
  }
}
