@use "@styles/variables" as variable;

.fieldset {
  border: none;
  width: 100%;
  display: block;
  .legend {
    color: variable.$primaryColor;
    font-family: variable.$titlesFont;
    display: block;
  }
  .Wrapper {
    padding: 0.4rem 0;
    display: grid;
    gap: 1rem;
  }
}
