@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.menu {
  display: none;
  .navigation {
    li {
      display: inline-block;
      padding: 0 15px;

      &:first-child {
        padding-left: 0;
      }
      a {
        display: block;
        color: variable.$blackColor;
        font-size: variable.$size16;
        line-height: 20px;
        font-family: variable.$defaultFont;
        padding: 16px 0;
        text-transform: uppercase;
        position: relative;
        &:hover {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 10px;
            z-index: 9;
            background-color: #76bf72;
          }
        }
      }

      &:hover {
        a.linkProgramme::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 11px;
          bottom: -5px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("/static/images/header/shap_nav.png");
          background-size: auto 11px;
        }
      }
    }
    a.linkProgramme {
      padding-right: 15px;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: url("/static/images/header/menu_item_picto.png");
      position: relative;
    }
  }
}
.mobile {
  li {
    background: none;
    padding-left: 0;
    text-align: center;
    padding: 6px 0;
    border-top: 1px solid #ededed;
    border-bottom: 0;
    a {
      font-family: variable.$titlesFont;
      color: #191919;
      font-size: 17px;
      letter-spacing: 0.4px;
      padding: 0;
      text-align: left;
      text-transform: uppercase;
    }
    &.number {
      padding-top: 12px;
      padding-bottom: 12px;
      display: flex;
      justify-content: center;
      a {
        text-transform: uppercase;
        color: variable.$primaryColor;
        font-size: 28px;
        line-height: 29px;
        display: inline-block;
        span {
          font-family: variable.$titlesFont;
          display: block;
          text-transform: uppercase;
          color: #000000;
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
          position: relative;
          text-align: center;
          letter-spacing: 0.6px;
          &::before {
            content: "";
            display: block;
            height: 1px;
            width: 15px;
            background-color: #7f7f7f;
            position: absolute;
            left: 0;
            top: 50%;
          }
          &::after {
            content: "";
            display: block;
            height: 1px;
            width: 15px;
            background-color: #7f7f7f;
            position: absolute;
            right: 0;
            top: 50%;
          }
        }
        &.compteMobile {
          display: none;
        }
      }
    }
  }
}

@include mixin.mediaTablet() {
  .mobile {
    li {
      &.number {
        display: flex;
        a {
          max-width: 80%;
        }
        .compteMobile {
          font-family: variable.$titlesFont;
          display: block !important;
          background-image: url("/static/images/header/picto-compte-ref.png");
          background-repeat: no-repeat;
          background-size: 20px auto;
          color: #000000;
          font-size: 15px;
          min-height: 31px;
          line-height: 31px;
          padding-left: 0;
          padding-top: 28px;
          background-position: center top;
          text-align: center;
          position: absolute;
          right: 4%;
          top: 5px;
          text-transform: none;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .menu {
    display: block;
  }
}
