@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.header {
  background-color: variable.$whiteColor;
  padding: 10px 0;
  box-shadow: 0 8px 20px -10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
  .wrap {
    position: static;
    max-width: 1140px;
    padding: 0 10px;
    > div {
      align-items: center;
    }
    .hamburger {
      padding: 24px 0 20px;
      a {
        display: block;
        width: 29px;
        height: 24px;
        font-size: 0;
        text-indent: -99999px;
        span {
          display: block;
          width: 100%;
          height: 2px;
          background-color: #000000;
          margin: 8.5px 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        &.close {
          background-image: url("/static/images/header/menu_mobile_close_ref.png");
          background-size: auto 100%;
          span {
            display: none;
          }
        }
      }
    }
  }
  .mobileMenu {
    background-color: #fff;
    max-width: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 6;
    box-shadow: 0px 10px 8px -6px #bbbcbd;
    border-top: 2px dotted #000;
  }
}
@include mixin.mediaDesktopSize() {
  .header {
    .mobileMenu {
      display: none !important;
    }
  }
}
@include mixin.mediaTablet() {
  .header {
    padding: 10px 0;
    .wrap {
      max-width: 90% !important;
      padding: 0;
    }
  }
}
@include mixin.mediaMobile() {
  .header {
    .wrap {
      max-width: 90% !important;
      .hamburger {
        padding: 10px 0;
      }
    }
    .mobileMenu {
      max-width: 100%;
      margin-top: 10px;
    }
  }
}

@include mixin.mediaSmallMobile() {
  .header {
    .wrap {
      max-width: 90% !important;
      .hamburger {
        padding: 8px 0 5px;
        a {
          background-size: 100% auto;
          width: 22px;
          height: 20px;
          span {
            margin: 6.5px 0;
          }
        }
      }
    }
  }
}
