@use "@styles/mixins" as mixin;

.loginModal {
  width: 810px;
}
.productModal {
  width: 954px;
  border: 0 !important;
  transform: translateX(-50%) !important;
  top: 0 !important;
  > button {
    width: 25px !important;
    height: 25px !important;
    background-size: cover;
  }
}

@include mixin.mediaTablet() {
  .loginModal {
    width: 610px;
    min-width: 300px !important;
  }
  .productModal {
    min-width: 0 !important;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0 20px;
    background-color: transparent !important;
    > div {
      background: #ffffff;
    }
    > button {
      right: 25px !important;
    }
  }
}
@include mixin.mediaMobile() {
  .loginModal {
    width: 90%;
  }
  .productModal {
    padding: 0 15px;
  }
}
@include mixin.mediaSmallMobile() {
  .productModal {
    padding: 0 10px;
  }
}
