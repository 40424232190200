.kr-input-field {
  color: #000000 !important;
}
.kr-embedded {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kr-fields-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.kr-fields-container > div {
  width: 100%;
  margin: 0;
}

.kr-payment-button {
  font-family: "Vinyl OT";
  background-color: #ff7a00;
  border: 1px solid #ff7a00;
  line-height: 50px;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  padding: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  display: block;
  position: relative;
  position: relative;
  border-radius: 50px;
  margin-bottom: 15px;
}
.kr-payment-button:hover {
  background-color: transparent;
  border-color: #ff7a00;
  color: #ff7a00;
}
.kr-pan {
  flex: 3;
  display: flex;
  align-items: center;
}
.kr-expiry {
  margin-left: 20px;
  flex: 1;
}
.kr-security-code {
  margin-left: 20px;
  flex: 1;
}
.kr-card-holder-name {
  margin-left: 0;
  flex: 1 1;
  width: 100%;
  margin-top: 20px;
}
.kr-card-holder-name input::placeholder {
  color: #999999;
}
.kr-card-holder-name input {
  width: 100% !important;
  outline: 0;
}
.kr-field-element {
  border-bottom: none;
  height: 45px;
  background-color: #fff;
  padding: 13px 10px;
  border-radius: 5px;
  min-height: 45px;
}
#account-update-expiry {
  margin: 0 !important;
}
#account-update-security-code {
  margin: 0 !important;
}
.kr-embedded .kr-payment-button:disabled {
  cursor: wait !important;
}
