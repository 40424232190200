@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.calendar {
  display: flex;
  align-items: center;
  justify-content: left;
  span {
    font-family: variable.$entryTitleFont;
    font-size: variable.$size16;
    color: variable.$blackColor;
    margin-left: 5px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
