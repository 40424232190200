@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.navbar {
  padding-top: 8px;
}
.brand {
  width: 175px;
}
.rightNav {
  padding: 11px 0;
  > ul {
    > li {
      display: inline-block;
      vertical-align: top;
      padding: 0 12px;
      position: relative;
      &.linkNumber {
        padding: 0;
        a {
          .icon {
            width: 41px;
            height: 41px;
            border-radius: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: variable.$orangeLightColor;
          }
          .label,
          .tel {
            display: none;
          }
        }
      }
      a {
        display: flex;
        align-items: flex-end;
        color: #000;
        font-family: variable.$titlesFont;
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .brand {
    width: auto;
  }
  .rightNav {
    padding: 3px 0;
    justify-content: flex-end;
    display: flex;
    &.inverse {
      > ul {
        > li {
          &.linkNumber {
            a {
              background-color: variable.$whiteColor;
              border-color: variable.$whiteColor;
            }
          }
          a {
            img {
              filter: contrast(1) invert(1);
            }
          }
        }
      }
    }
    > ul {
      display: flex;
      align-items: center;
      > li {
        &.linkNumber {
          padding-right: 30px;
          padding-left: 10px;
          a {
            font-family: variable.$titlesFont;
            display: block;
            text-transform: uppercase;
            color: variable.$blackColor;
            font-size: variable.$size22;
            line-height: 23px;
            display: flex;
            align-items: center;
            height: 45px;
            border: 1px solid variable.$lightWheat;
            border-radius: 45px;
            padding: 2px 20px 2px 2px;
            .icon {
              margin-right: 10px;
            }
            .tel {
              display: block;
            }
            .label {
              font-family: variable.$titlesFont;
              display: block;
              text-transform: uppercase;
              color: #000000;
              font-size: 8px;
              line-height: 10px;
              font-weight: normal;
              position: relative;
              text-align: center;
              letter-spacing: 0.6px;
            }
          }
        }
        &.linkCart {
          margin: 0;
          a {
            font-size: 0;
            text-indent: -9999px;
            padding: 0;
            width: 29px;
            line-height: 0;
            height: 27px;
            min-width: 29px;
          }
        }
      }
    }
  }
}
@include mixin.mediaTablet() {
  .brand {
    margin-left: 17%;
  }
}

@include mixin.mediaMobile() {
  .brand {
    width: 130px;
    margin-left: 0;
  }
  .rightNav {
    padding: 10px 0;
    > ul {
      > li.linkCart {
        margin: 0;
        a {
          font-size: 0;
          text-indent: -9999px;
          padding: 0;
          width: 29px;
          line-height: 0;
          height: 27px;
          min-width: 29px;
        }
      }
    }
  }
}
@include mixin.mediaSmallMobile() {
  .brand {
    width: 100px;
  }
  .rightNav {
    padding: 3px 0;
    > ul {
      > li.linkCart {
        a {
          width: 19px;
          height: 25px;
        }
      }
    }
  }
}
