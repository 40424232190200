@use "variables" as variable;
@use "mixins" as mixin;

/* |\\\\\\ General |\\\\\\  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
  -ms-overflow-style: scrollbar;
}
body.modal-open {
  height: 100vh;
  padding-right: 16px !important;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  /*text-align:left;*/
}
body {
  font-family: variable.$primaryFont;
  background: #ffffff;
  overflow-x: hidden;
  padding: 0 !important;
}

a {
  background-color: transparent;
  text-decoration: none;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.3em;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.outline-none {
  outline: none;
}
.lk-wrap {
  width: 100%;
  max-width: 985px;
  margin: 0 auto;
  position: relative;
  &.large {
    max-width: 1200px;
  }
}
.lk-grid {
  display: grid;
  &.twoCol {
    grid-template-columns: repeat(2, auto);
  }
  &.space-between {
    justify-content: space-between;
  }
}
.hide {
  display: none;
}
.lk-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-align-items: -moz-stretch;
  -webkit-align-items: -webkit-stretch;
  -ms-align-items: -ms-stretch;
  align-items: stretch;
  text-align: center;
  width: 100%;
  padding: 0;
}
.lk-flex.space-between {
  justify-content: space-between;
}
.lk-flex.space-center {
  justify-content: center;
}
.lk-flex.align-start {
  align-items: start;
}
.lk-flex.align-items {
  align-items: center;
}
.lk-flex.flex-wrap,
.lk-flex.lk-wrap {
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.pt-2 {
  padding-top: 20px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pt-4 {
  padding-top: 40px;
}
.ml-2 {
  margin-left: 20px;
}
.w-80 {
  min-width: 80px;
}
.h-80 {
  min-height: 80px;
}
.full-width {
  width: 100% !important;
}
.no-margin {
  margin: 0 !important;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.page-contenu {
  background: #f8f5f2;
}
.page-contenu {
  padding-bottom: 10px;
  .bloc-contenu {
    margin-bottom: 35px;
  }
  p {
    color: #727885;
    font-family: variable.$primaryFont;
    font-size: 1em;
    margin-bottom: 25px;
  }
  ul {
    margin-bottom: 20px;
  }
  li {
    color: #727885;
    font-size: 1em;
    font-family: variable.$primaryFont;
    margin-bottom: 10px;
    padding-left: 24px;
    background: url("/static/images/icons/daily-btn-fleche.png") no-repeat left
      4px;
  }
  strong {
    font-family: variable.$primaryFontBold;
  }
  a {
    color: variable.$orangeColor;
    font-family: variable.$primaryFont;
    text-decoration: underline;
  }
  h2,
  h3,
  h4 {
    font-family: variable.$titlesFont;
    color: #333;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    margin-bottom: 15px;
  }
  em {
    text-decoration: underline;
  }
}
input.input-text {
  background-color: #fff;
  border: 1px solid variable.$accountBorderColor;
  color: #222325;
  display: block;
  font-family: variable.$interFont;
  font-size: variable.$size14;
  height: 33px;
  line-height: 33px;
  margin-bottom: 15px;
  text-indent: 5px;
  width: 100%;
  outline: 0 none;
  border-radius: 3px;
  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.32);
  }
}
body .react-tel-input .form-control {
  max-width: 100%;
  border-color: variable.$accountBorderColor;
  border-radius: 3px;
  height: 33px;
}
body .react-tel-input .flag-dropdown {
  background-color: #fbf4e9;
  border-color: #dccfbe;
}
body .css-1m38plu-control {
  border-color: #dccfbe;
}
.css-1jqq78o-placeholder {
  color: rgba($color: #000000, $alpha: 0.32) !important;
  font-family: variable.$interFont;
}
button {
  outline: none;
}
button:hover {
  background-color: variable.$buttonColorHover;
}
input.input-text::-webkit-outer-spin-button,
input.input-text::-webkit-inner-spin-button {
  margin: 0;
}
input.input-text[type="number"] {
  margin: 0;
}
.show-tab-mobile {
  display: none !important;
}
.super-main {
  min-height: 400px;
}
.lk-blc-title-s1 {
  font-family: variable.$titlesFont;
  font-weight: normal;
  text-transform: uppercase;
  color: #333333;
  font-size: 45px;
  line-height: 42px;
  position: relative;
  margin-bottom: 40px;
  letter-spacing: 1px;
  > div {
    position: relative;
    display: inline-block;
    padding: 0 60px;
    > img {
      display: inline-block;
      margin-right: 15px;
      vertical-align: bottom;
    }
  }
  &.multiple {
    margin-bottom: 15px;
  }
}
.lk-blc-title-s1.blanc {
  color: #ffffff;
  .splayer::after,
  .splayer::before {
    background-color: #ffffff;
  }
}
.lk-blc-title-s1.center {
  text-align: center;
}
.lk-blc-stitle {
  text-align: center;
  color: #333;
  font-size: 22px;
  font-family: variable.$primaryFont;
  text-transform: none;
  margin-top: 5px;
  margin-bottom: 25px;
}
.lk-separation {
  height: 32px;
  background-image: url("/static/images/bg//config_bg1.jpg");
  background-repeat: repeat-x;
  background-position: right top;
  margin-bottom: 15px;
}
.slick-prev,
.slick-next {
  background-image: url("/static/images/icons/nav-carousel-rec2.png") !important;
  width: 80px;
  height: 80px;
  opacity: 1;
  text-indent: -99999px;
  z-index: 9;
}
.slide-avis .slick-prev,
.slide-avis .slick-next {
  background-image: url("/static/images/icons/nav-carousel3.png") !important;
  background-size: 82px auto !important;
  width: 41px;
  height: 41px;
  opacity: 1;
}
.slick-repas .slick-prev,
.slick-repas .slick-next {
  background-image: url("/static/images/icons/nav-carousel-green.png") !important;
}
.slide-avis .slick-prev {
  left: -50px;
}
.slide-avis .slick-next {
  right: -50px;
}
.slick-next {
  left: auto;
  background-position: right top !important;
}
.slick-arrow.slick-disabled {
  opacity: 0.3 !important;
}
.slider-bundles {
  .slick-prev,
  .slick-next {
    background-image: url("/static/images/icons/nav-carousel12.png") !important;
    width: 50px;
    height: 50px;
  }
  .slick-prev {
    left: -65px;
  }
  .slick-next {
    right: -65px;
  }
  .slick-current {
    > div > div > div {
      &::before {
        background-image: url("/static/images/icons/pastille-top-on.png");
      }
      > div {
        box-shadow: 0px 1px 4px 4px #c3b2ab;
        background-color: #fff;
        .two,
        .one {
          color: variable.$primaryColor;
        }
        .tree {
          color: #333333;
        }
        .four {
          color: #949494;
        }
      }
    }
  }
}

.dl-recette-slider .slick-prev {
  left: 16%;
}
.dl-recette-slider .slick-next {
  right: 16%;
}
.dl-recette-slider .slick-current + .slick-slide + .slick-slide > div {
  transform: scale(1.1);
  max-width: 100%;
}
.dl-recette-slider.slick-repas .slick-slide > div > div {
  &::after {
    content: "";
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 93%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: right bottom;
    transition: transform 0.2s;
    background-image: url("/static/images/bg/slider_hover.png");
  }
}
.dl-recette-slider.slick-repas
  .slick-current
  + .slick-slide
  + .slick-slide
  > div {
  .no-hover {
    display: none !important;
  }
  > div {
    &::after {
      display: block;
    }
  }
}
.shippingType {
  display: none;
}
.erreur-field,
.alert-text {
  font-family: variable.$primaryFont;
  color: #ff000d;
  font-weight: bold;
  font-size: 17px;
}
.required-field::after {
  content: "*";
  color: variable.$blackColor;
  margin-left: 3px;
  display: inline;
}
.erreur-field {
  font-size: 12px;
  display: block;
  position: relative;
  margin-top: -10px;
  margin-bottom: 10px;
}
.erreur-field.inLeft {
  text-align: left;
}
.erreur-field::before {
  display: inline;
  content: "⚠ ";
}
.erreur-field.atp {
  margin-top: 0;
}
.splayer::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 35px;
  top: 25px;
  height: 2px;
  background-color: #bbb;
  z-index: 9;
}
.splayer::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 35px;
  top: 25px;
  height: 2px;
  background-color: #bbb;
  z-index: 9;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}
.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}
.error-color {
  color: variable.$alertColor;
}
.success-color {
  color: variable.$primaryColor;
}
.text-left {
  text-align: left;
}
.text-medium-size {
  font-size: variable.$mediumSize;
}
.home-page {
  display: flex;
  flex-direction: column;
  > section {
    order: 3;
    &:first-child {
      order: 0;
    }
    &.how {
      order: 1;
    }
  }
}
.section-footer {
  order: 20;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.only_mobile {
  display: none;
}
.lk-gourmandises-repas .listing {
  padding-top: 0;
  box-shadow: none;
  max-width: 1120px;
  margin: auto;
}

body .forcing-new-btn {
  .blog-section a,
  .how-section a,
  .head .lk-wrap a {
    @include mixin.buttonDefault;
    border-radius: 45px;
    max-width: max-content;
    inline-size: max-content;
    padding-inline: 16px;
    margin: 0 auto;
    img {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .dl-recette-slider .slick-current + .slick-slide + .slick-slide > div {
    transform: none;
  }
  .dl-recette-slider .slick-current + .slick-slide > div {
    transform: scale(1.1);
    max-width: 100%;
    .no-hover {
      display: none !important;
    }
    > div {
      &::after {
        display: block;
      }
    }
  }
  .dl-recette-slider.slick-repas
    .slick-current
    + .slick-slide
    + .slick-slide
    > div {
    .no-hover {
      display: block !important;
    }
    > div {
      &::after {
        display: none;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .super-main {
    min-height: calc(100vh - 342px);
    display: flex;
    flex-direction: column;
    width: 100%;
    > div {
      flex: 1;
    }
  }
}

@include mixin.mediaTablet() {
  .hide-tab-mobile {
    display: none !important;
  }
  .show-tab-mobile {
    display: block !important;
  }
  .lk-blc-title-s1 {
    font-size: 6vw;
    margin-bottom: 6vw;
  }
  .lk-wrap-content {
    padding: 0 2%;
  }
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    opacity: 1;
    background-size: 80px auto !important;
  }
  .slider-bundles {
    .slick-prev,
    .slick-next {
      width: 30px;
      height: 30px;
      background-size: 60px auto !important;
    }
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
  }
}
@include mixin.mediaSmallTab() {
  .lk-blc-title-s1 {
    font-size: 6.5vw;
    line-height: 6.6vw;
  }
  .lk-flex-smaltab-colomn {
    flex-direction: column;
  }
}
@include mixin.mediaMobile() {
  .hide-mobile {
    display: none !important;
  }
  .lk-blc-title-s1 {
    font-size: 8vw;
    line-height: 7vw;
    margin-bottom: 10vw;
    > div {
      padding: 0;
    }
  }
  .lk-blc-stitle {
    font-size: 18px;
  }
  .splayer::after,
  .splayer::before {
    display: none;
  }
  .lk-flex-mob-colomn {
    flex-direction: column;
  }
  .only_tab_pc {
    display: none !important;
  }
  .only_mobile {
    display: block;
  }
  .dl-recette-slider .slick-current + .slick-slide > div {
    transform: none;
    max-width: 100%;
    > div {
      &::after {
        display: none;
      }
    }
  }
  .dl-recette-slider .slick-current > div {
    transform: scale(1.1);
    max-width: 100%;
  }
  .dl-recette-slider.slick-repas .slick-current > div {
    > div {
      &::after {
        display: block;
        height: 94%;
      }
    }
  }
  .dl-recette-slider.slick-repas .slick-prev {
    left: 7%;
  }
  .dl-recette-slider.slick-repas .slick-next {
    right: 7%;
  }
}
@include mixin.mediaSmallMobile() {
  html,
  body {
    min-width: 320px;
  }
  .lk-blc-title-s1 {
    font-size: 8.5vw;
    line-height: 7vw;
    > div {
      img {
        display: block;
        margin: 0 auto 10px auto !important;
      }
    }
  }
}
@include mixin.mediaSmallTabletSize() {
  .slick-slide:last-child,
  .slick-slide:nth-last-child(2) {
    visibility: hidden;
  }
}
