@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.stepsContainer {
  padding: 0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: variable.$whiteColor;
  .menuProg {
    width: 100%;
    .boxMenu {
      display: flex;
      font-size: variable.$size14;
      font-family: variable.$defaultFont;
      color: #bcbcbc;
      text-align: center;
      text-transform: uppercase;
      .stepBox {
        width: 25%;
        position: relative;
        &.activeStep,
        &.prevStep {
          color: variable.$blackColor;
          a {
            color: variable.$blackColor;
          }
        }
        em {
          display: none;
        }
        &:after {
          content: "";
          right: 0;
          top: 50px;
          top: calc(50% - 3.5px);
          position: absolute;
          width: 5px;
          height: 5px;
          border-bottom: 1px solid variable.$blackColor;
          border-right: 1px solid variable.$blackColor;
          transform: rotate(-45deg);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .stepsContainer {
    .menuProg {
      .boxMenu {
        .stepBox {
          em {
            display: inline-block;
            i {
              display: inline-block;
              border: 3px solid transparent;
              border-left-color: currentColor;
              vertical-align: middle;
              margin: -3px 4px 0;
            }
          }
        }
      }
    }
  }
}
